import {
  BrandName,
  eLaunchNowCustomerSupportEmail,
  eLaunchNowPrivacyPolicyUrl,
  eLaunchNowTermsAndConditionsUrl,
  EnvironmentParameters
} from '@libs/shared/bms-common/environment/environment.model';

export const environment: EnvironmentParameters = {
  googleTrackingId: 'G-YQYVF20EPX',
  production: false,
  environmentEndpoint: '/api/environment',
  usaApiUrl: 'https://api.test.app.elaunchnow.com',
  europeApiUrl: 'https://api.test.app.staffnow.aero',
  auth: {
    initialUrl: 'https://backoffice.test.staffnow.aero',
    jwtIssuer: 'https://keycloak.test.app.staffnow.aero/realms/staffnow-rc',
    keycloakLoginUrl:
      'https://keycloak.test.app.staffnow.aero/realms/staffnow-rc/protocol/openid-connect/token',
    clientId: 'staffnow-rc-login'
  },
  brandConfig: {
    brandName: BrandName.eLAUNCHNow,
    privacyPolicy: eLaunchNowPrivacyPolicyUrl(),
    termsAndConditions: eLaunchNowTermsAndConditionsUrl(),
    customerSupportEmail: eLaunchNowCustomerSupportEmail(),
    secondCustomerSupportEmail: eLaunchNowCustomerSupportEmail(),
    infoEmail: eLaunchNowCustomerSupportEmail(),
    appUrl: 'https://test.app.elaunchnow.com/',
    partnerAppUrl: 'https://test.staffnow.aero/'
  },
  euDashboardsEndpoint:
    'https://avc0fkmzk5.execute-api.eu-west-1.amazonaws.com/default/reports/',
  usaDashboardsEndpoint:
    'https://rtvdkospod.execute-api.us-east-1.amazonaws.com/default/reports/',
  privacyPolicyUrl: 'https://www.launchtws.com/privacy-policy/',
  termsAndConditionsUrl: 'https://www.launchtws.com/terms-of-use/',
  techniciansContactUrl: 'https://staffnow.aero/#contact-for-technicians',
  companiesContactUrl: 'https://staffnow.aero/contact'
};
